import {
  authHeader,
  authSignOut,
  authUpdateToken,
  getApiDomain,
} from "../contexts/Auth";
import moment from "moment";

window.apiWorking = false;
class transport {
  constructor(endpoint, options) {
    this.options = options;
    this.endpoint = endpoint;
  }

  async send(query, variables, operationName) {
    let payload = {
      query: query,
      variables: variables,
      operationName: operationName,
    };
    let options = await this.buildOptions(payload);
    let _this = this;
    window.apiWorking = true;
    return fetch(this.endpoint, options)
      .then(function (response) {
        if (
          response.headers.has("Authorization") &&
          !authUpdateToken(response.headers.get("Authorization"))
        ) {
          console.error("Failed to update token from header", response.headers);
          return Promise.reject("Unable to update token from header");
        }
        // 200 is for success
        // 400 is for bad request
        if (response.status !== 200 && response.status !== 400) {
          return Promise.reject("Invalid status code: " + response.status);
        }
        window.apiWorking = false;
        return response.json();
      })
      .then(function (json) {
        if (json.code)
          console.error("Found error code in GraphQL response", json);
        if (Number(json.code) === 103) {
          authSignOut(_this.options.dispatch);
          return Promise.reject(json.message);
        }
        var data = json.data;
        var errors = json.errors;

        if (errors && _this.options.handleErrors) {
          _this.options.handleErrors(errors, data);
          return null;
        }

        return data;
      });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async buildOptions(payload) {
    let header = authHeader();
    while (!header) {
      //console.debug('No auth header, sleeping...');
      await this.sleep(500);
      header = authHeader();
    }
    return {
      ...this.options,
      method: "post",
      headers: {
        "Accept-Language": window.language,
        Accept: "application/json",
        "Content-Type": "application/json",
        ...header,
      },
      body: JSON.stringify(payload),
      // To pass cookies to the server. (supports CORS as well)
      credentials: "include",
    };
  }
}

class graphQLApi {
  constructor(dispatch, history, onError, options = {}) {
    this.onError = onError;
    this.dispatch = dispatch;
    this.history = history;
    this.options = {
      handleErrors: this.handleErrors,
      dispatch: dispatch,
      ...options,
    };
    this.client = null;
  }

  getClient() {
    if (this.client === null) {
      try {
        const Lokka = require("lokka").Lokka;
        this.client = new Lokka({
          transport: new transport(getApiDomain() + "/graphql", this.options),
        });
      } catch (e) {
        authSignOut(this.dispatch);
        return null;
      }
    }
    return this.client;
  }

  tableQuery(tableState, columns, query, fields = {}, filter = "", args = "") {
    if (args.length) args += ",";
    args += "page:" + (tableState.page + 1);
    args += ",limit:" + tableState.pageSize;
    if (tableState.orderBy)
      args += ",sorting:" + JSON.stringify(tableState.orderBy.field);
    if (tableState.orderDirection)
      args += ",direction:" + JSON.stringify(tableState.orderDirection);
    tableState.filters.forEach((element) => {
      filter += (filter.length ? "," : "") + element.column.field + ":";
      //console.log('Setting query filter', element.column.field, element.column.tableData.columnOrder, columns[element.column.tableData.columnOrder]);
      switch (columns[element.column.tableData.columnOrder].type) {
        case "boolean":
          filter += element.value === "checked" ? "true" : "false";
          break;
        case "date":
          filter =
            filter.substr(0, filter.length - 1) +
            '_date:"' +
            moment(element.value).format("YYYY-MM-DD") +
            '"';
          break;
        case "numeric":
          filter += Number(element.value);
          break;
        default:
          filter += JSON.stringify(element.value);
      }
    });
    if (tableState.search && tableState.search.length > 0) {
      filter +=
        (filter.length ? "," : "") + "q:" + JSON.stringify(tableState.search);
    }
    if (filter.length) args += ",filter:{" + filter + "}";
    return this.query(
      `{ ${query}(${args}) { data { ${fields} } total current_page } }`
    );
  }

  query(query, vars = {}) {
    return this.getClient()
      .query(query, vars)
      .catch((e) => {
        this.handleException(e);
      });
  }

  mutate(query, vars = {}) {
    this.getClient();
    return this.getClient()
      .mutate(query, vars)
      .catch((e) => {
        this.handleException(e);
      });
  }

  handleException(e) {
    console.error("GraphQL API caught an exception", e);
    authSignOut(this.dispatch);
  }

  handleErrors(errors, data) {
    console.debug("Error data", data);
    console.error("GraphQL Error caught", errors, this);
    if (this.onError) {
      for (let i in errors) {
        if (errors[i].extensions.hasOwnProperty("validation")) {
          this.onError(errors[i].extensions.validation);
        } else {
          alert("GraphQL API Error: " + errors[i].message);
        }
      }
    }
  }
}

function graphQLReduceFields(fields = {}, returns = "initial") {
  let o = {};
  let a = [];
  let s = "";
  for (let i in fields) {
    if (fields[i].input === "heading") continue;
    switch (returns) {
      case "initial":
        if (fields[i].key) {
          if (o[fields[i].field] === undefined) {
            o[fields[i].field] = {};
          }
          o[fields[i].field][fields[i].key] = fields[i].initial;
        } else {
          o[fields[i].field] = fields[i].initial;
        }
        break;
      case "vars":
        s = fields[i].field;
        switch (fields[i].type) {
          default:
            s += ":$" + fields[i].field;
        }
        a.push(s);
        break;
      case "fields":
        if (fields[i].no_fetch !== true) {
          switch (fields[i].type) {
            case "Json":
            case "[ID]":
            case "ID":
              s = fields[i].field.replace("_id", "") + "{ id ";
              if (Array.isArray(fields[i].titleField)) {
                s += fields[i].titleField
                  .map((v) => {
                    if (v.indexOf(".") !== -1) {
                      let temp = v.split(".");
                      return temp.join("{id ") + "}".repeat(temp.length - 1);
                    } else return v;
                  })
                  .join(" ");
              } else if (fields[i].titleField.indexOf(".") !== -1) {
                s += fields[i].titleField.replace(".", " { ") + " } ";
              } else {
                s += fields[i].titleField;
              }
              if (fields[i].extraFields) {
                s += " " + fields[i].extraFields;
              }
              s += " }";
              break;
            default:
              s = fields[i].field + " ";
          }
          if (fields[i].input === "file") {
            a.push(s);
            s = fields[i].field + "_uri ";
          }
          a.push(s);
        }
        break;
      case "vars_def":
        s = "$" + fields[i].field;
        switch (fields[i].type) {
          case "Json":
          case "Email":
            s += ":String";
            break;
          default:
            s += ":" + fields[i].type;
        }
        if (fields[i].required) {
          s += "!";
        }
        a.push(s);
        break;
      case "validation":
        o[fields[i].field] = [];
        break;
      default:
    }
  }
  switch (returns) {
    case "validation":
    case "initial":
      return o;
    case "fields":
      return a.join(" ");
    case "vars":
    case "vars_def":
      return a.join(", ");
    default:
  }
}

export { graphQLReduceFields, graphQLApi };
