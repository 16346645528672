import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Fingerprint} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function SystemTokenEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.token_id);
    let system_id = Number(props.match.params.system_id);
    let customer_id = Number(props.match.params.customer_id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "system_tokens.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "is_active",
            initial: !id,
            type: "Boolean",
            label: intl.formatMessage({id: "system_tokens.edit.label.is_active", defaultMessage: "Is Active"}),
            input: "switch"
        }
    ];
    if (id) {
        fields.push({
            disabled: () => true,
            field: "token",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "system_tokens.edit.label.token", defaultMessage: "Token"}),
            input: "text"
        });
        fields.push({
            disabled: () => true,
            field: "last_used_at",
            initial: null,
            type: "String",
            label: intl.formatMessage({id: "system_tokens.edit.label.last_used_at", defaultMessage: "Last used at"}),
            input: "datetime"
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Fingerprint/>}
                        title={intl.formatMessage({
                            id: "system_tokens.edit.heading",
                            defaultMessage: "System Tokens edit"
                        })}/>
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"systemTokens"}
                                         mutations={"systemToken"}
                                         fields={fields}
                                         extraArgs={",system_id:" + system_id}
                                         cols={2}
                                         redirectToEdit={true}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
