import React from "react";
import {Grid} from "@material-ui/core";
import {Business} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";

export default function CustomerList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'customers.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'customers.list.column.city', defaultMessage:'City'}), field: 'city'},
        {title: intl.formatMessage({id:'customers.list.column.country', defaultMessage:'Country'}), field: 'country'},
        {title: intl.formatMessage({id:'customers.list.column.telephone', defaultMessage:'Telephone'}), field: 'telephone'},
        {title: intl.formatMessage({id:'customers.list.column.email', defaultMessage:'Email'}), field: 'email', type: 'string'}
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    title={intl.formatMessage({id:'customers.list.table.heading', defaultMessage:"Customers list"})}
                    columns={columns}
                    query="customers"
                    mutations="customer"
                    fields='id name city country telephone email'
                    icon={<Business/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}