import React from "react";
import {Fingerprint} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";

export default function SystemTokenList(props) {
    const intl = useIntl();
    const location = useLocation();

    let system_id = Number(props.match.params.system_id);
    if (props.system_id) {
        system_id = props.system_id;
    }

    const columns = [
        {
            title: intl.formatMessage({
                id: 'systems.edit.system_tokens.column.name',
                defaultMessage: 'Name'
            }), field: 'name'
        },
        {
            title: intl.formatMessage({
                id: 'systems.edit.system_tokens.column.is_active',
                defaultMessage: 'Is Active'
            }), field: 'is_active', type: 'boolean', initial: false
        },
        {
            title: intl.formatMessage({
                id: 'systems.edit.system_tokens.column.last_used_at',
                defaultMessage: 'Last used at'
            }), field: 'last_used_at', type: 'datetime'
        },
        {
            title: intl.formatMessage({
                id: 'systems.edit.system_tokens.column.created_at',
                defaultMessage: 'Created at'
            }), field: 'created_at', type: 'datetime'
        }
    ];

    return (
        <GraphQLTable
            softDeletes={true}
            inCard={false}
            baseUri={location.pathname + '/tokens'}
            title={intl.formatMessage({
                id: 'systems.edit.system_tokens.table.heading',
                defaultMessage: "Tokens"
            })}
            columns={columns}
            query="systemTokens"
            mutations="systemToken"
            fields='id system{name} name token is_active last_used_at created_at'
            filter={'system_id:'+system_id}
            icon={<Fingerprint/>}
            {...props}
            options={{
                ...props.options,
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
        />
    );
}