import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Apps} from "@material-ui/icons";
import Comments from "components/Comments/Comments";
import {authUser, useAuthDispatch} from "contexts/Auth";
import {graphQLApi, graphQLReduceFields} from "services/GraphQLApi";
import EditForm from "components/Form/EditForm";
import Smtp from "./Drivers/Smtp";
import SureSMS from "./Drivers/SureSMS";
import ChannelPriceList from "./ChannelPriceList";

function getChannelServices(intl) {
    return [
        {
            id: "App\\Services\\ChannelDrivers\\Test",
            name: intl.formatMessage({id: "channel.driver.test", defaultMessage: "Test"})
        },
        {
            id: "App\\Services\\ChannelDrivers\\Smtp",
            name: intl.formatMessage({id: "channel.driver.smtp", defaultMessage: "E-mail SMTP"})
        },
        {
            id: "App\\Services\\ChannelDrivers\\SureSMS",
            name: intl.formatMessage({id: "channel.driver.suresms", defaultMessage: "SureSMS"})
        },
    ]
}

const driverFields = {
    "App\\Services\\ChannelDrivers\\Test": () => [],
    "App\\Services\\ChannelDrivers\\Smtp": Smtp,
    "App\\Services\\ChannelDrivers\\SureSMS": SureSMS,
};

export default function ChannelEdit(props) {
    const intl = useIntl();

    let id = parseInt(props.match.params.channel_id);

    const f = [
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channels.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channels.edit.label.description", defaultMessage: "Description"}),
            input: "text"
        },
        {
            column: 1,
            field: "key",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channels.edit.label.key", defaultMessage: "System key"}),
            input: "text"
        },
        {
            column: 1,
            field: "class",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channels.edit.label.driver", defaultMessage: "Channel driver"}),
            options: getChannelServices(intl),
            onChange: (value, data, setData) => {
                setData({...data, class: value, ...graphQLReduceFields(getSettingsFields(value), "initial")});
                appendServiceFields(value);
            }
        },
        {
            field: "settings",
            column: 2,
            input: "heading",
            label: intl.formatMessage({id: "channels.edit.label.settings", defaultMessage: "Driver settings"}),
        },
    ];
    if (!authUser().hasRole('customer')) {
        f.unshift({
            column: 1,
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "channels.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        });
    }
    const [fields, setFields] = useState(f);

    const getSettingsFields = (driver) => {
        if (driverFields[driver] && typeof driverFields[driver] === "function") {
            return driverFields[driver](intl).map(f => {
                return {...f, field: "settings_" + f.field}
            });
        }
        return [];
    };

    const appendServiceFields = (driver) => {
        let driverFields = getSettingsFields(driver);
        setValidation({...validation, ...graphQLReduceFields(driverFields, "validation")});
        setFields([...fields.filter(f => f.column === 1 || f.field === "settings"), ...driverFields]);
        return driverFields;
    }

    const [isLoading, setIsLoading] = useState(false);
    const [validation, setValidation] = useState(graphQLReduceFields(fields, 'validation'));
    const [channel, setChannel] = useState(graphQLReduceFields(fields, 'initial'));
    const client = useCallback(new graphQLApi(useAuthDispatch(), props.history), []);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            client
                .query(
                    "{ channels(filter:{id:" + id + "}) { data {" +
                    "  id customer{id name} name description key class settings " +
                    " }}" +
                    "}"
                )
                .then((result) => {
                    if (result && result.hasOwnProperty("channels")) {
                        let data = result.channels.data[0];
                        let settings = JSON.parse(data.settings);
                        if (!settings) settings = {};
                        getSettingsFields(data.class).forEach(f => {
                            let settingsKey = f.field.substr(9);
                            data[f.field] = settings[settingsKey] !== undefined ? settings[settingsKey] : f.initial;
                        });
                        data.customer_id = data.customer ? data.customer : null;
                        setChannel(data);
                        appendServiceFields(data.class);
                    }
                    setIsLoading(false);
                });
        }
    }, [client, id]);

    const save = () => {
        setValidation(graphQLReduceFields(fields, "validation"));
        let query =
            "(" + (id ? "$id:ID!," : "") + "$customer_id:ID, $name:String, $description:String, , $key:String, $class:String, $settings:String) " +
            "{ response: channel" + (id ? "Update" : "Create") +
            "(" + (id ? "id:$id, " : "") + "customer_id:$customer_id, name:$name, description:$description, key:$key, class:$class, settings:$settings) " +
            "{ id } }";
        let settings = {};
        getSettingsFields(channel.class).forEach(f =>
            settings[f.field.substr(9)] = channel[f.field]
        );
        let variables = {
            customer_id: channel.customer_id ? channel.customer_id.id : null,
            name: channel.name,
            description: channel.description,
            price_unit: parseInt(channel.price_unit),
            price: parseFloat(channel.price),
            key: channel.key,
            class: channel.class,
            settings: JSON.stringify(settings),
        };
        if (id) {
            variables.id = id;
        }
        client.mutate(query, variables).then(r => {
            setIsLoading(true);
            if (r && r.hasOwnProperty('response')) {
                setIsLoading(false);
                props.history.goBack();
            }
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Apps/>}
                        title={intl.formatMessage({id: "channels.edit.heading", defaultMessage: "Channels edit"})}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <form onSubmit={save}>
                                    <EditForm
                                        fields={fields}
                                        cols={2}
                                        isLoading={isLoading}
                                        data={channel}
                                        setData={setChannel}
                                        save={save}
                                        validation={validation}
                                        history={props.history}
                                        buttons={[
                                            {
                                                onClick: _ => props.history.push('/admin/notifications?channel='+id),
                                                label: intl.formatMessage({id:"channels.edit.buttons.show_notifications", defaultMessage:"Show notifications"})
                                            }
                                        ]}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <ChannelPriceList
                                    {...props}
                                    inCard={false}
                                    channel_id={id}
                                    options={{
                                        pageSize: 10,
                                        pageSizeOptions: [10, 15, 20, 25, 50]
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} hidden={!id}>
                {id ? <Comments channelId={id} history={props.history}/> : ''}
            </Grid>
        </Grid>
    );
}
