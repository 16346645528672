export function updateItemInArray(items, key, match, attributes) {
  let index = items.findIndex(x=> x[key] === match);
  if (index === -1) {
    return [
      ...items,
      Object.assign({}, {[key]: match}, attributes),
    ];
  }
  else {
    return [
      ...items.slice(0, index),
      Object.assign({}, items[index], attributes),
      ...items.slice(index + 1),
    ];
  }
}

export const ckeditorConfig = {
  language: window.language,
  toolbar: [
    "heading",
    "|",
    "fontSize",
    "fontColor",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "superscript",
    "subscript",
    "|",
    "alignment",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "blockQuote",
    "|",
    "insertTable",
    "|",
    "undo",
    "redo",
  ],
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],
  },
  fontSize: {
    options: [9, 11, 13, 15, 17, 19, 21],
  },
  fontColor: {
    colors: [
      {
        color: "#000",
        label: "Black",
      },
      {
        color: "#444",
        label: "Dim grey",
      },
      {
        color: "#888",
        label: "Grey",
      },
      {
        color: "#BBB",
        label: "Light grey",
      },
      {
        color: "#FFF",
        label: "White",
      },
      {
        color: "#ED5945",
        label: "Red",
      },
      {
        color: "#82F9BF",
        label: "Light green",
      },
      {
        color: "#46C089",
        label: "Green",
      },
      {
        color: "#008957",
        label: "Dark green",
      },
      {
        color: "#2ab5e8",
        label: "Blue",
      },
      {
        color: "#8c2ae8",
        label: "Purple",
      },
      {
        color: "#F7CE3B",
        label: "Yellow",
      },
      {
        color: "#FF4F79",
        label: "Pink",
      },
      {
        color: "#480A42",
        label: "Magenta",
      },
    ],
  },
};

export function getFontColorFromColor(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}
