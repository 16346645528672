import React from "react";
import {Grid} from "@material-ui/core";
import {Apps} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";

export default function ChannelList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'channels.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'channels.list.column.key', defaultMessage:'System key'}), field: 'key'},
        {title: intl.formatMessage({id:'channels.list.column.class', defaultMessage:'Driver'}), field: 'class', render: r => r.class.split("\\").pop()},
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    title={intl.formatMessage({id:'channels.list.table.heading', defaultMessage:"Channels list"})}
                    columns={columns}
                    query="channels"
                    mutations="channel"
                    fields='id name class key'
                    icon={<Apps/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}