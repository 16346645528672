import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { graphQLApi } from "../../services/GraphQLApi";
import { useAuthDispatch } from "../../contexts/Auth";
import moment from "moment";
import GraphQLEditForm from "../GraphQL/GraphQLEditForm";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  media: {
    width: "auto",
    maxWidth: "100%",
    // paddingTop: '56.25%', // 16:9
    // backgroundSize: 'contain',
  },
}));

export default function (props) {
  const classes = useStyles();
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);
  const [comments, setComments] = useState([]);

  let filter = "";
  if (props.hasOwnProperty('customerId')) {
    filter = "customer_id:" + props.customerId;
  }
  if (props.hasOwnProperty('systemId')) {
    filter = "system_id:" + props.systemId;
  }
  if (props.hasOwnProperty('channelId')) {
    filter = "channel_id:" + props.channelId;
  }
  if (props.userId) {
    filter = "created_by_user_id:" + props.userId;
  }
  useEffect(() => {
    stableClient
      .query(
        "{comments(filter:{" +
          filter +
          '},sorting:"created_at",direction:"desc"' +
          (props.userId ? ",limit:10" : "") +
          ") {data{id created_by_user{name} description attachment attachment_uri created_at commentable_type commentable{id name}}} }"
      )
      .then((r) => {
        if (r && r.hasOwnProperty("comments")) {
          setComments(
            r.comments.data.map((c) => {
              let type = "";
              switch (c.commentable_type) {
                case "App\\Models\\User":
                  type = intl.formatMessage({
                    id: "comments.types.user",
                    defaultMessage: "user",
                  });
                  break;
                default:
                  type = c.commentable_type.split("\\").pop();
              }
              return {
                ...c,
                type: type,
                image: /.+\.jpg|.+\.png|.+\.gif/gi.test(c.attachment),
              };
            })
          );
        }
      });
  }, [stableClient, filter, props.userId, intl]);

  return (
    <Grid container spacing={2}>
      {comments.map((c, key) => (
        <Grid item xs={12} key={"comment_" + key}>
          <Card>
            <CardHeader
              // action={
              //     <IconButton aria-label="settings">
              //         <MoreVert/>
              //     </IconButton>
              // }
              title={
                props.userId
                  ? intl.formatMessage(
                      {
                        id: "comments.header.comment_on",
                        defaultMessage: "Comment on {type}: {title}",
                      },
                      { type: c.type, title: c.commentable.name }
                    )
                  : intl.formatMessage(
                      {
                        id: "comments.header.comment_from",
                        defaultMessage: "Comment from user {user}",
                      },
                      { user: c.created_by_user.name }
                    )
              }
              subheader={moment(c.created_at).format("D. MMMM YYYY HH:mm")}
            />
            <Divider />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                dangerouslySetInnerHTML={{ __html: c.description }}
              />
              {c.image ? (
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={c.attachment_uri}
                  title={c.attachment}
                />
              ) : (
                <Link
                  href={c.attachment_uri}
                  target="_blank"
                  title={c.attachment}
                >
                  {c.attachment}
                </Link>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
      {props.userId ? (
        ""
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={intl.formatMessage({
                id: "comments.heading.new_comment",
                defaultMessage: "Write a new comment",
              })}
            />
            <CardContent>
              <GraphQLEditForm
                id={null}
                query="comments"
                mutations="comment"
                extraArgs={", " + filter}
                cols={1}
                fields={[
                  {
                    field: "description",
                    initial: "",
                    type: "String",
                    label: intl.formatMessage({
                      id: "comments.label.description",
                      defaultMessage: "Description",
                    }),
                    input: "html",
                    required: true,
                  },
                  {
                    field: "attachment",
                    initial: "",
                    type: "String",
                    label: intl.formatMessage({
                      id: "comments.label.attachment",
                      defaultMessage: "Attachment",
                    }),
                    input: "file",
                  },
                ]}
                {...props}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
