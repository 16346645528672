import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@material-ui/core";
import {NotificationsActive, Person} from "@material-ui/icons";
import {graphQLApi} from "services/GraphQLApi";
import {authRefresh, authUser, useAuthDispatch} from "contexts/Auth";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    tableCell: {
        borderTop: 0,
        borderBottom: 0,
    }
});

export default function (props) {
    const classes = useStyles();
    const intl = useIntl();
    let id = Number(props.match.params.id);

    const [alert, setAlert] = useState(false);
    const hideAlert = () => {
        setAlert(false);
    };
    useEffect(() => {
        //console.log('useEffect alert triggered', alert);
        if (alert) {
            setTimeout(hideAlert, 7500);
        }
    }, [alert]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [userRoles, setUserRoles] = useState([]);
    const [roles, setRoles] = useState([]);

    const isSelected = (id) => userRoles.find((v) => v.id === id) !== undefined;
    const handleClick = (event, id) => {
        if (isSelected(id)) {
            setUserRoles(userRoles.filter((v) => v.id !== id));
        } else {
            let t = [{id: id}];
            setUserRoles(t.concat(userRoles));
        }
    };

    const setUser = (user) => {
        setName(user.name);
        setEmail(user.email);
        setNewPassword("");
        setNewPasswordConfirm("");
        setUserRoles(user.roles);
    };

    const initialValidation = {
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
    };
    var [validation, setValidation] = useState(initialValidation);
    const setValidationFromErrors = (errors) => {
        setValidation({...initialValidation, ...errors});
    };

    var [isLoading, setIsLoading] = useState(false);

    const client = new graphQLApi(
        useAuthDispatch(),
        props.history,
        setValidationFromErrors
    );
    const stableClient = useCallback(client, []);
    useEffect(() => {
        let query = "roles { id title }";
        if (id) {
            query =
                "users(filter:{id:" +
                id +
                "}) { data { id name email roles { id } } } " +
                query;
        }
        setIsLoading(true);
        stableClient
            .query("{" + query + "}")
            .then((result) => {
                if (result.users) {
                    setUser(result.users.data[0]);
                    document.title += ': ' + result.users.data[0].name;
                }
                setRoles(result.roles);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error("Caught exception", e);
            });
    }, [id, stableClient]);

    const save = () => {
        setIsLoading(true);
        setValidation(initialValidation);
        let query =
            "($name: String!, $email: String!, $password: String!, $password_confirmation: String!, $roles: [ID]) " +
            "{ user: userCreate(name: $name, email: $email, password: $password, password_confirmation: $password_confirmation, roles: $roles) " +
            "{ id name email roles { id } } }";
        let variables = {
            name: name,
            email: email,
            password: newPassword,
            password_confirmation: newPasswordConfirm,
            roles: userRoles.map((v) => v.id),
        };
        if (id) {
            variables.id = id;
            query = query
                .replace("($name:", "($id: ID!, $name:")
                .replace("userCreate(", "userUpdate(id: $id, ");
        }
        client.mutate(query, variables).then((result) => {
            setIsLoading(false);
            if (result && result.user) {
                setUser(result.user);
                if (Number(authUser().id) === Number(id)) {
                    authRefresh(true);
                }
                setAlert(true);
                if (!id) {
                    props.history.push("/admin/user/" + result.user.id);
                }
            }
        });
    };

    return (<Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
                <Snackbar
                    color="success"
                    icon={NotificationsActive}
                    message={intl.formatMessage({
                        id: "user.edit.alert.success",
                        defaultMessage: "User was successfully saved!",
                    })}
                    open={alert}
                    onClose={() => setAlert(false)}
                />
                <Card>
                    <CardHeader color="primary" avatar={<Person/>} title={"User profile"}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={intl.formatMessage({
                                        id: "user.edit.label.name",
                                        defaultMessage: "Full name",
                                    })}
                                    id="name"
                                    error={validation.name.length > 0}
                                    helperText={validation.name.join(" ")}
                                    fullWidth
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={intl.formatMessage({
                                        id: "user.edit.label.email",
                                        defaultMessage: "Email address",
                                    })}
                                    id="email"
                                    error={validation.email.length > 0}
                                    helperText={validation.email.join(" ")}
                                    fullWidth
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={intl.formatMessage({
                                        id: "user.edit.label.password",
                                        defaultMessage: "New password",
                                    })}
                                    id="new_password"
                                    error={validation.password.length > 0}
                                    helperText={validation.password.join(" ")}
                                    fullWidth
                                    value={newPassword}
                                    type="password"
                                    autoComplete={false}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={intl.formatMessage({
                                        id: "user.edit.label.password_confirm",
                                        defaultMessage: "Confirm new password",
                                    })}
                                    id="new_password_confirmation"
                                    error={validation.password_confirmation.length > 0}
                                    helperText={validation.password_confirmation.join(" ")}
                                    fullWidth
                                    value={newPasswordConfirm}
                                    type={"password"}
                                    autoComplete={false}
                                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    justify={"flex-end"}
                                    style={{marginTop: "20px"}}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            {intl.formatMessage({id: "common.button.back"})}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={save}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <CircularProgress size={17} color="inherit"/>
                                            ) : (
                                                intl.formatMessage({
                                                    id: "user.edit.button.submit",
                                                    defaultMessage: "Save",
                                                })
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {authUser().hasRole(["sysdev", "admin", "manager"]) ? (
                <Grid item xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary" avatar={<Person/>} title={<FormattedMessage
                            id={"user.edit.table.roles.header"}
                            defaultMessage={"Roles assigned to the user"}
                        />}>
                        </CardHeader>
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    {roles.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell className={classes.tableCell} padding="checkbox">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name={"role-" + row.id}
                                                                color={"primary"}
                                                                checked={isItemSelected}
                                                                onChange={(event) => handleClick(event, row.id)}
                                                            />}
                                                            label={row.title}
                                                        />
                                                    </FormGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                ""
            )}
        </Grid>
    );
}
