import React from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";

// core components
import FooterLinks from "./FooterLinks";


const Footer = () => {
  return (
    <Box component="footer" width="100%" padding="1rem">
      <FooterLinks/>
    </Box>
  );
};

export default Footer;
