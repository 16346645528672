import React from "react";
import ReactDOM from "react-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import {LocaleContextProvider} from "contexts/Intl";
import {AuthProvider} from "contexts/Auth";
import {createBrowserHistory} from "history";
import App from "contexts/App";

const hist = createBrowserHistory();

ReactDOM.render(
    <AuthProvider history={hist}>
        <LocaleContextProvider>
            <App history={hist}/>
        </LocaleContextProvider>
    </AuthProvider>,
    document.querySelector("#root")
);
