import React from "react";
import {Grid} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";

export default function LanguageList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'languages.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'languages.list.column.locale', defaultMessage:'Locale'}), field: 'locale'}
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    title={intl.formatMessage({id:'languages.list.table.heading', defaultMessage:"Languages list"})}
                    columns={columns}
                    query="languages"
                    mutations="language"
                    fields='id name locale'
                    icon={<Language/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}