import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export default function ConfirmDialog(props) {
  const { onClose, open, title, message, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="default">
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button onClick={handleOk} color="primary">
          <FormattedMessage id="common.button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
