import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons components
// core components
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import componentStyles from "assets/theme/components/admin-navbar.js";
import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({breadcrumb, history}) {
    const classes = useStyles();
    const location = useLocation();

    let match = {};
    let params = breadcrumb[breadcrumb.length-1].href.match(/:[a-z_]+/g);
    if (params) {
        let regexUri = breadcrumb[breadcrumb.length-1].href.replaceAll(new RegExp('/:[a-z_\-]+', 'g'), '/([0-9]+)')
        let values = location.pathname.match(regexUri);
        params.forEach((p, i) => {
            match[p.substr(1)] = values[i + 1];
        });
    }

    return (
        <>
            <AppBar
                position="fixed"
                color="transparent"
                elevation={0}
                classes={{root: classes.appBarRoot}}
            >
                <Toolbar disableGutters>
                    <Container
                        maxWidth={false}
                        component={Box}
                        classes={{root: classes.containerRoot}}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Breadcrumbs aria-label="breadcrumb">
                                {breadcrumb.slice(0, -1).map((b,k) => {
                                    if (b.component) {
                                        let href = b.href;
                                        let id = b.href.match(/:[a-z_]+/);
                                        if (id) {
                                            id = id.pop();
                                        }
                                        else {
                                            id = '';
                                        }
                                        for (let m in match) {
                                            href = href.replace(':'+m, match[m]);
                                            id = id.replace(':'+m, match[m]);
                                        }
                                        return (<Link key={"breadcrumb-" + k} color="inherit"
                                                     onClick={() => history.push(href)}>
                                            {b.name + (id ? ' #'+id : '')}
                                        </Link>);
                                    }
                                    return (<Typography key={"breadcrumb-" + k}>{b.name}</Typography>);
                                })}
                                <Typography color="textPrimary">{breadcrumb[breadcrumb.length - 1].name}</Typography>
                            </Breadcrumbs>
                            <Box display="flex" alignItems="center" width="auto">
                                {/*<Box
                  display="flex"
                  alignItems="center"
                  width="auto"
                  marginRight="1rem"
                  className={classes.searchBox}
                >
                  <Search className={classes.searchIcon} />
                  <InputBase
                    placeholder="Search"
                    classes={{
                      input: classes.searchInput,
                    }}
                  />
                </Box>*/}
                                <NavbarDropdown history={history}/>
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
}
