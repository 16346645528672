export default function (intl) {
    return [
        {
            column: 2,
            field: "host",
            initial: "",
            label: intl.formatMessage({id: "channels.driver.smtp.label.host", defaultMessage: "Host"}),
        },
        {
            column: 2,
            field: "port",
            initial: 25,
            label: intl.formatMessage({id: "channels.driver.smtp.label.port", defaultMessage: "Port"}),
            type: "number",
        },
        {
            column: 2,
            field: "encryption",
            initial: "tls",
            label: intl.formatMessage({id: "channels.driver.smtp.label.encryption", defaultMessage: "Secure transport"}),
            options: [
                {id:"", name:"None"},
                {id:"tls", name:"TLS"},
                {id:"ssl", name:"SSL"},
            ]
        },
        {
            column: 2,
            field: "username",
            initial: "",
            label: intl.formatMessage({id: "channels.driver.smtp.label.username", defaultMessage: "Username"}),
            inputProps: {autoComplete: "off"},
        },
        {
            column: 2,
            field: "password",
            initial: "",
            label: intl.formatMessage({id: "channels.driver.smtp.label.password", defaultMessage: "Password"}),
            type: "password",
            inputProps: {autoComplete: "off"}
        }
    ];
}
