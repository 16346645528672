import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Money} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ChannelPriceEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.price_id);
    let channel_id = parseInt(props.match.params.channel_id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channel_prices.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "filter",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "channel_prices.edit.label.filter", defaultMessage: "Filter"}),
            input: "text"
        },
        {
            field: "price",
            initial: "",
            type: "Float",
            label: intl.formatMessage({id: "channel_prices.edit.label.price", defaultMessage: "Price"}),
            input: "number"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Money/>}
                        title={intl.formatMessage({id: "channel_prices.edit.heading", defaultMessage:"Prices edit"})}/>
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"channelPrices"}
                                         mutations={"channelPrice"}
                                         fields={fields}
                                         extraArgs={",channel_id:"+channel_id}
                                         cols={3}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
