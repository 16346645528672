import React from "react";
import {Grid} from "@material-ui/core";
import {Money} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";

export default function ChannelPriceList(props) {
    const intl = useIntl();
    const location = useLocation();
    const columns = [
        {title: intl.formatMessage({id:'channel_prices.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'channel_prices.list.column.filter', defaultMessage:'Filter'}), field: 'filter'},
        {title: intl.formatMessage({id:'channel_prices.list.column.price', defaultMessage:'Price'}),
            field: 'price', type: 'numeric', render: row => intl.formatNumber(row.price, {minimumFractionDigits:6})}
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    baseUri={location.pathname + '/prices'}
                    title={intl.formatMessage({id:'channel_prices.list.table.heading', defaultMessage:"Prices"})}
                    columns={columns}
                    query="channelPrices"
                    mutations="channelPrice"
                    fields='id channel{name} name filter price'
                    filter={"channel_id:"+props.channel_id}
                    icon={<Money/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}