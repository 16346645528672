import React, {useState} from "react";
import {NotificationsActive, People} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import {Grid, makeStyles, Snackbar} from "@material-ui/core";
import {authUser} from "contexts/Auth";
import moment from "moment";
import componentStyles from "assets/theme/layouts/admin.js";

const useStyles = makeStyles(componentStyles);
moment.locale(window.language);

export default function (props) {
    const classes = useStyles();
    const intl = useIntl();
    const [showAlert, setShowAlert] = useState(false);
    const columns = [
        {
            title: intl.formatMessage({
                id: "user.list.column.title",
                defaultMessage: "Title",
            }),
            field: "title",
        },
        {
            title: intl.formatMessage({
                id: "user.list.column.name",
                defaultMessage: "Name",
            }),
            field: "name",
        },
        {
            title: intl.formatMessage({
                id: "user.list.column.email",
                defaultMessage: "Email",
            }),
            field: "email",
        },
        {
            title: intl.formatMessage({
                id: "user.list.column.phone",
                defaultMessage: "Phone",
            }),
            field: "phone",
        },
        {
            title: intl.formatMessage({
                id: "user.list.column.can_login",
                defaultMessage: "Can login",
            }),
            field: "can_login",
            type: "boolean",
            filtering: false,
        },
        {
            title: intl.formatMessage({
                id: "user.list.column.created",
                defaultMessage: "Created",
            }),
            align: "right",
            field: "created_at",
            type: "date",
            filtering: false,
            render: (row) => moment(row.created_at).format("YYYY-MM-DD"),
        }
    ];

    return (<Grid container>
            <Grid item xs={12}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    color="warning"
                    icon={NotificationsActive}
                    message={intl.formatMessage({
                        id: "user.list.alert.not-allowed-to-delete-user",
                        defaultMessage: "You are not allowed to delete this user!",
                    })}
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    autoHideDuration={6000}
                />
                <GraphQLTable
                    deletable={(row) => {
                        if (parseInt(row.id) === parseInt(authUser().id)) {
                            setShowAlert(true);
                            return false;
                        }
                        return true;
                    }}
                    title={
                        intl.formatMessage({
                            id: "user.list.title",
                            defaultMessage: "User list",
                        })
                    }
                    columns={columns}
                    query="users"
                    mutations="user"
                    fields="id name email can_login created_at roles{key}"
                    icon={<People/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}
