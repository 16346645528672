import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Divider, Grid} from "@material-ui/core";
import {Business} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import Comments from "../../../components/Comments/Comments";
import SystemList from "../Systems/SystemList";
import {authUser} from "../../../contexts/Auth";

export default function CustomerEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.customer_id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "address",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.address", defaultMessage: "Address"}),
            input: "text"
        },
        {
            field: "zip",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.zip", defaultMessage: "Zip"}),
            input: "text"
        },
        {
            field: "city",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.city", defaultMessage: "City"}),
            input: "text"
        },
        {
            field: "country",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.country", defaultMessage: "Country"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            field: "telephone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.telephone", defaultMessage: "Telephone"}),
            input: "text"
        },
        {
            column: 3,
            field: "vat",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.vat", defaultMessage: "VAT #"}),
            input: "text"
        },
        {
            column: 3,
            field: "ean",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.ean", defaultMessage: "EAN #"}),
            input: "text"
        },
        {
            column: 3,
            disabled: () => !authUser().hasRole(['admin','manager','accountant','sales']),
            field: "discount",
            initial: "",
            type: "Int",
            label: intl.formatMessage({id: "customers.edit.label.discount", defaultMessage: "Discount (%)"}),
            input: "number"
        },
        {
            column: 3,
            disabled: () => !authUser().hasRole(['admin','manager','accountant']),
            field: "balance",
            initial: "",
            type: "Float",
            label: intl.formatMessage({id: "customers.edit.label.balance", defaultMessage: "Account balance"}),
            input: "number",
            unit: "DKK",
            inputProps: {
                step: 0.000001
            }
        },
        {
            column: 3,
            field: "invoicing_email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.invoicing_email", defaultMessage: "Invoicing Email"}),
            input: "email"
        }
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Business/>}
                        title={intl.formatMessage({id: "customers.edit.heading", defaultMessage: "Customer edit"})}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GraphQLEditForm id={id}
                                                 query={"customers"}
                                                 mutations={"customer"}
                                                 fields={fields}
                                                 cols={3}
                                                 {...props}
                                />
                            </Grid>
                            <Grid item xs={12} hidden={!id}>
                                <Divider/>
                            </Grid>
                            {id ? <Grid item xs={12}>
                                <SystemList {...props} customer_id={id}/>
                            </Grid> : ''}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} hidden={!id}>
                <Comments customerId={id} history={props.history}/>
            </Grid>
        </Grid>
    );
}
