export default function (intl) {
    return [
        {
            column: 2,
            field: "username",
            initial: "",
            label: intl.formatMessage({id: "channels.driver.suresms.label.username", defaultMessage: "Username"}),
        },
        {
            column: 2,
            field: "password",
            initial: "",
            label: intl.formatMessage({id: "channels.driver.suresms.label.password", defaultMessage: "Password"}),
        },
        {
            column: 2,
            field: "number_filter",
            initial: '',
            label: intl.formatMessage({id: "channels.driver.suresms.label.number_filter", defaultMessage: "List of allowed numbers"}),
            help: intl.formatMessage({id: "channels.driver.suresms.help.number_filter", defaultMessage: "Leave empty to allow any that pass price filters"}),
        },
        {
            column: 2,
            field: "system_sender_id",
            initial: false,
            input: "switch",
            label: intl.formatMessage({id: "channels.driver.suresms.label.system_sender", defaultMessage: "Use system name as sender"}),
            help: intl.formatMessage({id: "channels.driver.suresms.label.system_sender_help", defaultMessage: "System names must be approved before use"}),
        },
    ];
}
