const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "250px",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      marginTop: "5rem",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
});

export default componentStyles;
