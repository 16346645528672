import React, {useEffect, useState} from "react";
import {Grid, Paper, TextField} from "@material-ui/core";
import {Send} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import ReactJson from "react-json-view";
import {Alert} from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import {graphQLApi} from "../../services/GraphQLApi";
import {useAuthDispatch} from "../../contexts/Auth";
import {useLocation} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function NotificationList(props) {
    const intl = useIntl();
    const query = useQuery();
    console.log(query.get('system'));
    const [systems, setSystems] = useState([]);
    const [channels, setChannels] = useState([]);
    const client = new graphQLApi(useAuthDispatch(), props.history);
    useEffect(() => {
        client.query('{systems{data{id name}} channels{data{id name}}}').then(r => {
            if (r?.hasOwnProperty('systems')) {
                setSystems(r.systems.data);
            }
            if (r?.hasOwnProperty('channels')) {
                setChannels(r.channels.data);
            }
        });
    }, []);

    const columns = [
        {
            title: intl.formatMessage({id: 'notifications.list.column.system.name', defaultMessage: 'System'}),
            field: 'system_id',
            type: 'string',
            filtering:!query.has('system'),
            filterComponent: ({columnDef, onFilterChanged}) => <TextField
                    fullWidth
                    select
                    size="small"
                    defaultValue={query.get('system')}
                    onChange={event => onFilterChanged(columnDef.tableData.id, event.target.value)}
                >
                    <MenuItem value={null}><em>{intl.formatMessage({id:"common.select.all", defaultMessage:"All"})}</em></MenuItem>
                    {systems.map(s => <MenuItem value={s.id}>{s.name}</MenuItem>)}
                </TextField>,
            render: row => row.system.name,
            width: 'auto'
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.external_id', defaultMessage: 'External ID'}),
            field: 'external_id',
            type: 'numeric'
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.channel.name', defaultMessage: 'Channel'}),
            field: 'channel_id',
            type: 'string',
            filtering:!query.has('channel'),
            filterComponent: ({columnDef, onFilterChanged}) => <TextField
                fullWidth
                select
                size="small"
                defaultValue={query.get('channel')}
                onChange={event => onFilterChanged(columnDef.tableData.id, event.target.value)}
            >
                <MenuItem value={null}><em>{intl.formatMessage({id:"common.select.all", defaultMessage:"All"})}</em></MenuItem>
                {channels.map(s => <MenuItem value={s.id}>{s.name}</MenuItem>)}
            </TextField>,
            render: row => row.channel?.name,
            width: 'auto'
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.recipient', defaultMessage: 'Recipient'}),
            field: 'recipient',
            type: 'string'
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.delivery_attempts', defaultMessage: 'Attempts'}),
            field: 'delivery_attempts',
            type: 'numeric'
        },
        {
            title: intl.formatMessage({id: "notifications.list.column.delivered_at", defaultMessage: "Delivered At"}),
            field: "delivered_at",
            type: "datetime",
        },
        {
            title: intl.formatMessage({id: "notifications.list.column.failed_at", defaultMessage: "Failed At"}),
            field: "failed_at",
            type: "datetime",
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.created_at', defaultMessage: 'Created'}),
            field: 'created_at', type: 'datetime', defaultSort: "desc",
        },
        {
            title: intl.formatMessage({id: 'notifications.list.column.price', defaultMessage: 'Price'}),
            field: 'price', type: 'numeric', render: row => intl.formatNumber(row.price, {minimumFractionDigits: 6})
        },
    ];
    let filter = [];
    if (query.get('system'))
        filter.push('system_id:'+query.get('system'));
    if (query.get('channel'))
        filter.push('channel_id:'+query.get('channel'));

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    {...props}
                    title={<Grid container>
                        <Grid item component="h2" style={{flexGrow:0,paddingTop:14}}>{intl.formatMessage({
                            id: 'notifications.list.table.heading',
                            defaultMessage: "Notifications"
                        })}</Grid>
                        <Grid item style={{flexGrow:1,width:200}}></Grid>
                    </Grid>}
                    columns={columns}
                    filter={filter.join(', ')}
                    query="notifications"
                    fields='id system{name} external_id channel{name} system_token{id} message recipient delivered_at delivery_attempts price failed_at failure_reason created_at'
                    icon={<Send/>}
                    detailPanel={row => {
                        let failure = '';
                        if (row.failed_at) {
                            failure = (<Grid container spacing={1} style={{margin: "0.5em 0"}}>
                                {row.failure_reason.split('\n').map((m, k) => <Grid item xs={12}><Alert severity="error"
                                                                                                        key={'notification-row-' + row.id + '-' + k}>{m}</Alert></Grid>)}
                            </Grid>)
                        }
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Paper variant="outlined" style={{padding: "0.5em", margin: "0.5em 0"}}>
                                        <ReactJson name={null} src={JSON.parse(row.message)}
                                                   collapseStringsAfterLength={75}
                                                   enableClipboard={false}/>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>{failure}</Grid>
                            </Grid>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}